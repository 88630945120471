/* Sans Serif - Graphik */
@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-ThinItalic.otf') format('opentype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-Extralight.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-ExtralightItalic.otf') format('opentype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-LightItalic.otf') format('opentype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-RegularItalic.otf') format('opentype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-MediumItalic.otf') format('opentype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-SemiboldItalic.otf') format('opentype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-BoldItalic.otf') format('opentype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-Black.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-BlackItalic.otf') format('opentype');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-Super.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('/fonts/Graphik/Graphik-SuperItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}

/* Serif - Lora */
@font-face {
  font-family: 'Lora';
  src: url('/fonts/Lora/Lora-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Lora';
  src: url('/fonts/Lora/Lora-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Lora';
  src: url('/fonts/Lora/Lora-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Lora';
  src: url('/fonts/Lora/Lora-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Lora';
  src: url('/fonts/Lora/Lora-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Lora';
  src: url('/fonts/Lora/Lora-SemiBoldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Lora';
  src: url('/fonts/Lora/Lora-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Lora';
  src: url('/fonts/Lora/Lora-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}
