.ril-loading-circle-point {
  border: 3px solid rgba(18, 101, 84, 0.2);
  border-top: 3px solid white;
  border-radius: 50%;
  width: 1.875rem;
  height: 1.875rem;
  -webkit-animation: fEWCgj 0.6s linear infinite;
  animation: fEWCgj 0.6s linear infinite;
}

.ril-loading-circle-point::before {
  content: none;
}
